.rowContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 16px;
}

.statistics {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 4px;
}

.modalContainer {
  maxheight: 75vh;
  overflowy: auto;
  padding: 20px;
}
