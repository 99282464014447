.only {
  opacity: 0;
}

.only:hover {
  text-decoration: underline;
  cursor: pointer !important;
  color: #106ba3;
}

.flexSpaceBtw {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
