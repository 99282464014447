/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import '../../node_modules/@blueprintjs/core/lib/css/blueprint.css';
@import "../../node_modules/@blueprintjs/popover2/lib/css/blueprint-popover2.css";
/* @import "/@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import '../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css'; */

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

li {
  padding: 0;
  font-size: 10px;
  color: #666666;
  /* font-weight: bold; */
  /* font-family: 'Helvetica', Arial; */
  text-transform: uppercase;
  line-height: normal;
}

li.graph {
  clear: both;
  overflow: hidden;
  display: table;
  width: 100%;
  height: 22px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  /* box-shadow: inset 0px 0px 0px 1px rgb(0 0 0 / 20%); */
  border: 1px solid #b3b3b3;
}

li.graph .colors {
  display: table-row;
}

li.graph div {
  width: 10px;
  height: 22px;
}

li.graph .quartile {
  display: table-cell;
}

popup:before {
  content: '';
  position: absolute;
  bottom: -14px;
  left: 0;
  width: 0;
  height: 0;
  margin-left: 28px;
  border-left: 0px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid white;
  z-index: 2;
}

.sm-heading {
  text-transform: uppercase;
  text-align: left;
  color: #182026;
  font-weight: bold;
  font-size: 12px;
}

.mapboxgl-popup-close-button {
  background: white !important;
}

.bp3-menu {
  min-width: 169px !important;
}
.bp3-transition-container  {
  top: 6px !important;
}

.pointer {
  cursor: pointer !important;
}
